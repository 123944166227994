



















































































import { Nullable } from '@/types'
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { namespace } from 'vuex-class'
const BookingStore = namespace('BookingStore')
import { BookingModel } from '@/core/models/BookingModel'
import { toLocalMonth } from '@/utils/dateFormatter'

import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import Spinner from '@/components/Spinner.vue'

import VueCustomScrollbar from 'vue-custom-scrollbar'
import 'vue-custom-scrollbar/dist/vueScrollbar.css'

export interface DayInterface {
  bookings: number
  canceled: number
}

@Component({
  name: 'CalendarMonth',
  components: {
    BaseIcon,
    BaseButton,
    Spinner,
    VueCustomScrollbar,
  },
})
export default class extends Vue {
  @Prop({ type: Date, default: new Date() })
  value!: Date

  @Prop({ type: String, default: '' })
  calendarKey!: string

  @Watch('calendarKey')
  onCalendarKeyChanged(): void {
    this.getCurrentBookings()
  }

  @Watch('value')
  onValueChanged(): void {
    this.getCurrentBookings()
  }

  get today(): Date {
    return new Date(new Date().toDateString())
  }

  get first(): Date {
    const date = new Date(this.value.toDateString())
    date.setDate(1)
    return date
  }

  get beforeFirst(): Date {
    return this.daysFrom(this.first, -1)
  }

  get last(): Date {
    const date = new Date(this.first.getTime())
    date.setMonth(this.first.getMonth() + 1)
    date.setDate(0)
    return date
  }

  get afterLast(): Date {
    return this.daysFrom(this.last, 1)
  }

  get from(): Date {
    return this.daysFrom(this.first, -this.getDay(this.first))
  }

  get to(): Date {
    return this.daysFrom(this.last, 6 - this.getDay(this.last))
  }

  get startFromFirst(): boolean {
    return this.from.toDateString() === this.first.toDateString()
  }

  get endToLast(): boolean {
    return this.to.toDateString() === this.last.toDateString()
  }

  get dates(): Date[] {
    const dates: Date[] = []
    let date = new Date(this.from.getTime())
    while (date <= this.to) {
      dates.push(date)
      date = this.daysFrom(date, 1)
    }
    return dates
  }

  get days(): Map<number, DayInterface> {
    const days: Map<number, DayInterface> = new Map()
    this.bookingList.forEach((item) => {
      this.getDates(new Date(item.start), new Date(item.end)).forEach(
        (date) => {
          const day = days.get(+date)
          if (day) {
            day.bookings++
          } else {
            days.set(+date, { bookings: 1, canceled: 0 })
          }
        }
      )
    })
    return days
  }

  toLocalMonth = toLocalMonth

  loading = false
  weekDays = [
    'понедельник',
    'вторник',
    'среда',
    'четверг',
    'пятница',
    'суббота',
    'воскресенье',
  ]
  scrollbarSettings = {
    minScrollbarLength: 60,
  }

  @BookingStore.Action
  private getBookings!: (period: {
    from: Nullable<Date>
    to: Nullable<Date>
  }) => Promise<void>

  @BookingStore.Getter
  public bookingList!: BookingModel[]

  async created(): Promise<void> {
    this.getCurrentBookings()
  }

  async getCurrentBookings(): Promise<void> {
    this.loading = true
    await this.getBookings({
      from: this.daysFrom(this.from, -1),
      to: this.daysFrom(this.to, 1),
    })
    this.loading = false
  }

  daysFrom(date: Date, days: number): Date {
    const result = new Date(date.getTime())
    result.setDate(date.getDate() + days)
    return result
  }

  getDay(date: Date): number {
    return date.getDay() === 0 ? 6 : date.getDay() - 1 // make Sunday (0) the last day
  }

  getDates(start: Date, end: Date): Date[] {
    const dates: Date[] = []
    const stop = new Date(end.getTime())
    if (stop.getTime() === new Date(stop.toDateString()).getTime()) {
      stop.setSeconds(-1) // exclude 00:00:00
    }
    let date = new Date(start.toDateString())
    while (date <= stop) {
      dates.push(date)
      date = this.daysFrom(date, 1)
    }
    return dates
  }

  prev(): void {
    const date = new Date(this.value.getTime())
    date.setMonth(date.getMonth() - 1)
    this.$emit('input', date)
  }

  next(): void {
    const date = new Date(this.value.getTime())
    date.setMonth(date.getMonth() + 1)
    this.$emit('input', date)
  }
}
